@import "../../styles/colors.scss";
@import '../../styles/constants.scss';

$footer-breakpoint: 900px;

.footer {
  width: 100vw;

  padding: 80px 10% 50px;

  @media screen and (max-width: $mobile-screen) {
    padding: 40px 5% 30px;
  }

  display: flex;
  justify-content: center;
  background-color: $black-color;

  .footerContainer {
    width: 1108px;
    max-width: 100%;

    .footerNavsHolder {
      display: flex;
      justify-content: space-between;

      .footerNavColumn {
        p, h1 {
          user-select: none;
          cursor: pointer;
          color: $white-color;
        }

        .footerleadingNav {
          font-weight: 500;
          font-size: 17px;
        }
        
        .companyNav {
          cursor: initial;
        }
        .nevoChargingNav {
          color: $blue-color;
        }
        
        div {
          margin-top: 15px;

          p:first-child {
            margin-top: 0;
          }
          p {
            font-weight: 300;
            margin-top: 10px;
            font-size: 15px;
          }
        }  
      }

      @media screen and (max-width: $footer-breakpoint) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 15px;
      }
    }
  }
}

.policyCopyHolder {
  margin-top: 81px;

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $small-screen) {
    margin-top: 60px;
  }

  @media screen and (max-width: $footer-breakpoint) {
    flex-flow: column nowrap;
    justify-content: flex-start;
    row-gap: 60px;
  }
}