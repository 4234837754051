@import "./colors.scss";

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.homePage {
  min-height: 100vh;
  background: $black-color;

  animation: appear .5s ease-out;

  .hamburgerHolder {
    position: absolute;
    z-index: 1;

    top: 50px;
    right: 50px;
    width: 35px;

    @media screen and (max-width: 550px) {
      top: 38px;
      right: 35px;
      width: 25px;
    }
  }

  .logoContainer {
    z-index: 1;
    position: absolute;

    display: flex;
    justify-content: center;
    pointer-events: none;

    width: 100vw;

    .nevoLogo {
      margin-top: 51px;
      width: 264px;

      @media screen and (max-width: 1440px) {
        margin-top: 35px;
        width: 200px;
      }
      @media screen and (max-width: 550px) {
        margin-top: 30px;
        width: 121px;
      }
    }
  }
  
  .mainHeader {
    white-space: nowrap;
    
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    font-size: 80px;
    color: $white-color;

    text-align: center;
    pointer-events: none;

    span {
      pointer-events: none;
      color: inherit;
    }

    @media screen and (max-width: 1440px) {
      font-size: 58px;
    }
    @media screen and (max-width: 860px) {
      font-size: 36px;
    }
    @media screen and (max-height: 390px) {
      top: unset;
      bottom: 60px;
      transform: translate(-50%, 0);
    }
    @media screen and (max-height: 280px) {
      bottom: 34px;
    }
  }
}
