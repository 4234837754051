@import "../../styles/colors.scss";

.homeWrapper {
  display: flex;
  justify-content: center;

  .sweeperWrapper, .sweeperSlide {
    min-height: 400px;
  }

  .sweeperWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    
    display: flex;
    transition-property: transform;
    box-sizing: content-box;

    overflow: scroll;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  // removing scroll bar
  .sweeperWrapper::-webkit-scrollbar {
    display: none;
  }

  .sweeperSlide {
    cursor: pointer;  
    position: relative;
    
    width: 25%;
    height: 100vh;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: $black-color;

    transition: all 0.2s;

    flex-shrink: 0;

    @media screen and (max-width: 860px) {
      width: 100%;
    }
  }
  .shrinkPanel {
    width: 24%;
  }
  .extended {
    width: 28%;
  }

  // positioning images
  .sweeperSlide:nth-of-type(1) {
    background-position-x: 25%;
  }
  .sweeperSlide:nth-of-type(2) {
    background-position-x: 90%;
  }
  .sweeperSlide:nth-of-type(3) {
    background-position-x: 73%;
  }
  
  .contentContainer {
    height: 100%;
    
    padding-left: 8.5%;
    padding-bottom: 22px;

    display: flex;
    align-items: flex-end;

    @media screen and (min-width: 1920px) {
      padding-left: 41px;
    }

    @media screen and (max-width: 1440px) {
      padding-left: 1.5vw;
      padding-bottom: 0.7vw;
    }
    
    @media screen and (max-width: 860px) {
      padding-left: 23px;
      padding-bottom: 20px;
    }

    .textHolder {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      column-gap: 20px;
      
      .text {
        font-size: 1.55vw;
        color: $white-color;
      }
      .arrowDown {
        width: 13.5px;
      }

      @media screen and (min-width: 1920px) {
        .text {
          font-size: 30px;
        }
      }

      @media screen and (max-width: 1440px) {
        column-gap: 16px;

        .text {
          font-size: 22px;
        }
        .arrowDown {
          width: 10px;
        }
      }
      @media screen and (max-width: 1170px) {
        .text {
          font-size: 1.8vw;
        }
      }
    }
  }

  .controlsHolder {
    position: absolute;
    bottom: 68px;

    width: 100vw;
    padding-left: 23px;
    padding-right: 23px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    @media screen and (max-height: 280px) {
      bottom: 36px;
    }
    
    .text {
      display: block;

      width: 130px;
      height: 52px;

      color: $white-color;
      font-size: 26px;
      line-height: 26px;
    }
    
    .arrowsHolder {
      display: flex;
      column-gap: 50px;      

      .arrowHolder {
        width: 18px;
  
        .leftArrow {
          transform: rotate(180deg);
        }

        img {
          width: 18px;
          transition: opacity .2s;
        }
        .disabled {
          opacity: 0.5;
        }
      }
    }
  }
}